<template>
  <div class="container">
    <!-- 面包屑 -->
    <BaseBread>
      <BaseBreadItem to="/">首页</BaseBreadItem>
      <BaseBreadItem>联系我们</BaseBreadItem>
    </BaseBread>
    <!-- 单图片 -->
    <BaseOneImage :dataSource="dataSource" />
    <div class="link-list">
      <!-- 左：侧边栏组件 -->
      <LinkAside />
      <!-- 右：内容 -->
       <!-- 三级路由的出口 -->
       <RouterView />
    </div>
  </div>
</template>
<script>
import { useRoute } from "vue-router";
import useStore from "@/store";
import LinkAside from "./components/link-aside.vue";
import { findAd } from '@/api/home'
import { ref } from 'vue'
// // 获取当前路由对象
// const route = useRoute();
// // 获取动态路由参数的name
// const name = route.query.name;

export default {
  name: 'HomeBanner',
  components: { LinkAside },
  setup () {
    const dataSource = ref([])
    findAd("linkUsImage").then(data => {
      dataSource.value = data.data.list[0]
    })
    return { dataSource }
  }
}

</script>

<style scoped lang="less">
.link-list {
  display: flex;
  margin-top: 20px;
  .article {
    width: 1000px;
  }
}
</style>
