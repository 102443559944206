<template>
  <div class="base-link-aside">
    <div class="user-manage">
      <h4>联系我们</h4>
      <div class="links">
        <RouterLink to="/link/linkUs">关于我们</RouterLink>
        <RouterLink to="/link/companyCulture">企业文化</RouterLink>
        <RouterLink to="/link/honors">创新荣誉</RouterLink>
        <RouterLink to="/link/recruit">人才招聘</RouterLink>
        <RouterLink to="/link/contact">联系方式</RouterLink>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LinkAside',
  components: {},
}

</script>
<style scoped lang="less">
.base-link-aside {
  width: 220px;
  margin-right: 20px;
  border-radius: 2px;
  .user-manage {
    background-color: #fff;
    h4 {
      font-size: 18px;
      font-weight: 400;
      padding: 20px 52px 5px;
      border-top: 1px solid #f6f6f6;
    }

    .links {
      padding: 0 52px 10px;
    }

    a {
      display: block;
      line-height: 1;
      padding: 15px 0;
      font-size: 14px;
      color: #666;
      position: relative;

      &.router-link-exact-active {
        color:#666;
        &:before {
          display: block;
        }
      }

      &:hover {
        color: #666;
      }
      &.active {
        color: #666;

        &:before {
          display: block;
        }
      }

      &:before {
        content: "";
        display: none;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        position: absolute;
        top: 19px;
        left: -16px;
        background-color: #666;
      }
    }
  }
}
</style>
